// src/store/store.js
import { createStore } from "redux";

// Initial state

const initialState = {
  user:
    sessionStorage.loggedInUser != null &&
    sessionStorage.loggedInUser != undefined
      ? JSON.parse(sessionStorage.getItem("loggedInUser"))
      : null,
  isLoggedIn:
    sessionStorage.loggedInUser != null &&
    sessionStorage.loggedInUser != undefined
      ? true
      : false,
};

// Action types
const SET_USER = "SET_USER";
const LOGOUT_USER = "LOGOUT_USER";

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      console.log(action.payload);

      sessionStorage.setItem("loggedInUser", JSON.stringify(action.payload));
      return { ...state, user: action.payload, isLoggedIn: true };
    case LOGOUT_USER:
      sessionStorage.removeItem("loggedInUser");
      return { ...state, user: initialState.user, isLoggedIn: false };

    default:
      return state;
  }
};

// Create store
const store = createStore(reducer);

// Action creators
export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export default store;
