import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import Swal from "sweetalert2";

const Customer = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    customerName: "",
    phone: "",
    email: "",
    address: "",
  });
  const [errors, setErrors] = useState({});
  const [customers, setCustomers] = useState([]);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get("/customer");
      if (response?.status === "success") {
        setCustomers(response.data);
      } else {
        console.error("Error fetching customers:", response);
        setCustomers([]);
      }
    } catch (error) {
      setCustomers([]);
      console.error("Error fetching customers:", error);
    }
  };
  const handleModalOpen = (customer) => {
    setShowModal(true);
    if (customer == undefined || customer == null) {
      setFormData({ customerName: "", phone: "", email: "", address: "" });
      setErrors({});
    } else {
      setFormData(customer);
      setErrors({});
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    // Required fields
    if (!formData.customerName)
      newErrors.customerName = "Customer name is required.";
    if (!formData.phone) newErrors.phone = "Phone number name is required.";

    const phoneRegex = /^\d{10}$/; // Matches 10 digits (without any formatting)
    if (formData.phone && !phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits and Number.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (formData.id) {
        const data = new FormData();
        data.append("customerName", formData.customerName);
        data.append("phone", formData.phone);
        data.append("email", formData.email);
        data.append("address", formData.address);

        try {
          // Call the API using POST method
          const response = await axiosInstance.put(
            "/customer?id=" + formData.id,
            data
          );

          // Check the response from the API
          if (response?.status === "success") {
            console.log("Response data:", response);
            // Fetch updated customers after adding a new customer
            fetchCustomers();
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Customer " + response.message,
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                popup: "custom-toast",
              },
            });
            handleModalClose();
          } else {
            console.error("Error in response:", response);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        const data = new FormData();
        data.append("customerName", formData.customerName);
        data.append("phone", formData.phone);
        data.append("email", formData.email);
        data.append("address", formData.address);

        try {
          // Call the API using POST method
          const response = await axiosInstance.post("/customer", data);

          // Check the response from the API
          if (response?.status === "success") {
            console.log("Response data:", response);
            // Fetch updated customers after adding a new customer
            fetchCustomers();
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Customer " + response.message,
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                popup: "custom-toast",
              },
            });
            handleModalClose();
          } else {
            console.error("Error in response:", response);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`/customer?id=${id}`);
      if (response.data?.status === "success") {
        console.log("Customer deleted successfully:", response.data);
        // Fetch updated customers after deletion
        fetchCustomers();
      } else {
        console.error("Error deleting customer:", response.data);
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []); // Empty dependency array to run once on mount

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">Customers</h1>
            </div>
            <div className="col-sm-6 text-right align-self-center">
              <button
                onClick={() => {
                  handleModalOpen(null);
                }}
                className="align-top btn btn-sm btn-info"
              >
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card mx-3 shadow">
        <div className="card-body table-responsive">
          <table
            id="customer-list"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>S NO.</th>
                <th>Customers Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => (
                <tr key={customer.id}>
                  <td>{index + 1}</td>
                  <td>{customer.customerName}</td>
                  <td>{customer.phone}</td>
                  <td>{customer.email}</td>
                  <td>{customer.address}</td>
                  <td>
                    {/* Add actions like edit or delete here if needed */}
                    <button
                      className="btn btn-dark btn-sm"
                      onClick={() => handleModalOpen(customer)}
                    >
                      Edit
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDelete(customer.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>S NO.</th>
                <th>Customers Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      {/* Modal for adding new customer */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addCustomerModalLabel"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addCustomerModalLabel">
                {formData.id ? "Add New Customer" : "Edit Customer"}
              </h5>
              <button
                type="button"
                className="close"
                onClick={handleModalClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal"
                id="addCustomer"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-info shadow">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <div className="form-group mb-4">
                              <label>
                                Customer Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="customerName"
                                value={formData.customerName}
                                onChange={handleChange}
                                placeholder="Enter User Name"
                              />
                              {errors.customerName && (
                                <small className="text-danger">
                                  {errors.customerName}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group">
                              <label>
                                Phone Number{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="Enter Phone Number"
                              />
                              {errors.phone && (
                                <small className="text-danger">
                                  {errors.phone}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-group mb-4">
                              <label>Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter User Email"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-6">
                            <div className="form-group mb-4">
                              <label>Address</label>
                              <input
                                type="text"
                                className="form-control"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                placeholder="Enter User Address"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-info">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
