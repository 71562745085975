import React, { useRef } from "react";

const Receipt = () => {
  const receiptData = {
    customerId: 1,
    customerName: "dhamu",
    deliveryDate: "2024-10-02",
    discount: 0,
    netAmt: 5,
    orderDate: "2024-10-02",
    orderId: "ORD-0017",
    paidAmt: 0,
    paymentType: "",
    remark: "",
    services: [
      {
        price: 5,
        qty: 1,
        serviceId: 1,
        serviceName: "pant",
        serviceTypeId: 1,
        serviceTypeName: "iron",
      },
    ],
    totalAmt: 5,
  };

  const printReceipt = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Receipt</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 20px;
            }
            .receipt {
              border: 1px solid #000;
              padding: 20px;
              width: 300px;
              margin: auto;
            }
            h1 {
              text-align: center;
            }
            .section {
              margin-bottom: 10px;
            }
            .section p {
              margin: 5px 0;
            }
            .services {
              margin-top: 20px;
            }
            .service {
              border-bottom: 1px dashed #ccc;
              padding: 5px 0;
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          <div class="receipt">
            <h1>Receipt</h1>
            <div class="section">
              <p><strong>Order ID:</strong> ${receiptData.orderId}</p>
              <p><strong>Customer ID:</strong> ${receiptData.customerId}</p>
              <p><strong>Customer Name:</strong> ${receiptData.customerName}</p>
              <p><strong>Order Date:</strong> ${receiptData.orderDate}</p>
              <p><strong>Delivery Date:</strong> ${receiptData.deliveryDate}</p>
              <p><strong>Payment Type:</strong> ${receiptData.paymentType}</p>
              <p><strong>Discount:</strong> $${receiptData.discount}</p>
              <p><strong>Net Amount:</strong> $${receiptData.netAmt}</p>
              <p><strong>Paid Amount:</strong> $${receiptData.paidAmt}</p>
            </div>
            <div class="services">
              <h2>Services</h2>
              ${receiptData.services
                .map(
                  (service) => `
                <div class="service">
                  <p><strong>Service Name:</strong> ${service.serviceName}</p>
                  <p><strong>Service Type:</strong> ${service.serviceTypeName}</p>
                  <p><strong>Quantity:</strong> ${service.qty}</p>
                  <p><strong>Price:</strong> $${service.price}</p>
                </div>
              `
                )
                .join("")}
            </div>
            <div class="section">
              <p><strong>Total Amount:</strong> $${receiptData.totalAmt}</p>
              <p><strong>Remark:</strong> ${receiptData.remark}</p>
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  return (
    <div>
      <button onClick={printReceipt}>Print Receipt</button>
    </div>
  );
};

export default Receipt;
