import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import { useNavigate } from "react-router-dom";
const Service = () => {
  const [customers, setCustomers] = useState([]);
  const Navigate = useNavigate();
  const [serviceTypes, setServiceTypes] = useState([]);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get("/service");
      if (response?.status === "success") {
        setCustomers(response.data);
        const data = response.data;

        const response2 = await axiosInstance.get(
          "/serviceprice?columns=id,service,serviceid,price"
        );

        if (response2?.status === "success") {
          setServiceTypes(response2.data);
        }
      } else {
        console.error("Error fetching customers:", response);
        setCustomers([]);
      }
    } catch (error) {
      setCustomers([]);
      console.error("Error fetching customers:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`/service?id=${id}`);
      if (response?.status === "success") {
        console.log("servicetype deleted successfully:", response);
        // Fetch updated customers after deletion
        fetchCustomers();
      } else {
        console.error("Error deleting servicetype:", response);
      }
    } catch (error) {
      console.error("Error deleting servicetype:", error);
    }
  };

  const handleModalOpen = (customer) => {
    Navigate("/createservice", {
      state: { data: customer === null ? null : customer.id },
    });
  };

  useEffect(() => {
    fetchCustomers();
  }, []); // Empty dependency array to run once on mount

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">Services</h1>
            </div>
            <div className="col-sm-6 text-right align-self-center">
              <button
                type="button"
                data-toggle="modal"
                data-target="#modal-default"
                className="align-top btn btn-sm btn-info d-inline-block"
                onClick={() => {
                  handleModalOpen(null);
                }}
              >
                + Add New
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card mx-3 shadow">
        <div className="card-body table-responsive">
          <table
            id="service-list"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>S NO.</th>
                <th>Service Image</th>
                <th>Service Name</th>
                <th>Service Types</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => (
                <tr key={customer.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      id="image"
                      src={`${"../../images/" + customer.serviceImg}`}
                      alt=""
                      width="80px"
                      height="80px"
                    />
                  </td>
                  <td>{customer.serviceName}</td>
                  <td>
                    {serviceTypes.map((item, i) => (
                      <div>
                        {customer.id == item.serviceid
                          ? item.service + " "
                          : ""}
                      </div>
                    ))}
                  </td>
                  <td>{customer.active == 1 ? "Active" : "InActive"}</td>
                  <td>
                    {/* Add actions like edit or delete here if needed */}
                    <button
                      className="btn btn-dark btn-sm"
                      onClick={() => handleModalOpen(customer)}
                    >
                      Edit
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDelete(customer.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>S NO.</th>
                <th>Service Image</th>
                <th>Service Name</th>
                <th>Service Types</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Service;
