import React from "react";

const Dashboard = () => {
  return (
    <div className="content-wrapper">
      {/* Content Header */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <h1 className="m-0 text-dark">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          {/* Info boxes */}
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box info-box bg-gradient-info">
                <div className="inner">
                  <h3>2</h3>
                  <p>Pending Orders</p>
                </div>
                <div className="icon">
                  <i className="fa fa-shopping-bag"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box info-box bg-gradient-info">
                <div className="inner">
                  <h3>1</h3>
                  <p>Processing Order</p>
                </div>
                <div className="icon">
                  <i className="ion fa ion-stats-bars"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box info-box bg-gradient-info">
                <div className="inner">
                  <h3>4</h3>
                  <p>Ready to Deliver</p>
                </div>
                <div className="icon">
                  <i className="fa fa-thumbs-up"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box info-box bg-gradient-info">
                <div className="inner">
                  <h3>0</h3>
                  <p>Delivered Orders</p>
                </div>
                <div className="icon">
                  <i className="fa fa-check"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="card card-info shadow mx-2">
                <div className="card-header">
                  <h3 className="card-title">Today's Delivery</h3>
                </div>
                <div className="card-body" style={{ minHeight: "280px" }}>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Customer</th>
                          <th>Order Date</th>
                          <th>Order Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Sumit Shrestha</td>
                          <td>2023-09-21</td>
                          <td>
                            <span className="badge badge-info">
                              Ready for Delivery
                            </span>
                          </td>
                          <td>
                            <a
                              href="order-detail.html"
                              className="btn btn-info btn-sm"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Ramesh Kafle</td>
                          <td>2023-09-21</td>
                          <td>
                            <span className="badge badge-info">
                              Ready for Delivery
                            </span>
                          </td>
                          <td>
                            <a
                              href="order-detail.html"
                              className="btn btn-info btn-sm"
                            >
                              View
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card-footer">
                  <a
                    href="orders.html"
                    className="btn btn-sm btn-info float-right"
                  >
                    View All Orders
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card-info shadow mx-2">
                <div className="card-header">
                  <h3 className="card-title">Recent Orders</h3>
                </div>
                <div className="card-body" style={{ minHeight: "280px" }}>
                  <ul className="products-list product-list-in-card pl-2 pr-2">
                    <li className="item">
                      <div className="product-info">
                        <a href="order-detail.html" className="product-title">
                          Order 1
                        </a>
                        <span className="product-description">
                          2023-09-21 | $50
                        </span>
                      </div>
                    </li>
                    <li className="item">
                      <div className="product-info">
                        <a href="order-detail.html" className="product-title">
                          Order 2
                        </a>
                        <span className="product-description">
                          2023-09-20 | $30
                        </span>
                      </div>
                    </li>
                    <li className="item">
                      <div className="product-info">
                        <a href="order-detail.html" className="product-title">
                          Order 3
                        </a>
                        <span className="product-description">
                          2023-09-19 | $20
                        </span>
                      </div>
                    </li>
                    <li className="item">
                      <div className="product-info">
                        <a href="order-detail.html" className="product-title">
                          Order 4
                        </a>
                        <span className="product-description">
                          2023-09-18 | $40
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card-footer text-center">
                  <a href="orders.html" className="uppercase">
                    View All Orders
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
