import "./App.css";
import Dashboard from "./Dashboard/Dashboard";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login/Login";
import Customer from "./Customer/Customer";
import { useSelector } from "react-redux";
import ServiceType from "./Servicetype/Servicetype";
import Service from "./Service/Service";
import CreateService from "./Service/CreateService";
import Createorder from "./Order/Createorder";
function App() {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);

  const ProtectedRoute = ({ element, isLoggedIn }) => {
    return isLoggedIn ? element : <Navigate to="/login" replace />;
  };

  return (
    <div className="App">
      <div className="wrapper position-relative">
        {isLoggedIn ? (
          <>
            <Header />
            <SideMenu />
          </>
        ) : (
          ""
        )}
        <Routes>
          <Route
            path="/"
            element={
              <Navigate to={isLoggedIn ? "/dashboard" : "/login"} replace />
            }
          />
          <Route path="login" element={<Login />} />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute element={<Dashboard />} isLoggedIn={isLoggedIn} />
            }
          />
          <Route
            path="customer"
            element={
              <ProtectedRoute element={<Customer />} isLoggedIn={isLoggedIn} />
            }
          />
          <Route
            path="servicetype"
            element={
              <ProtectedRoute
                element={<ServiceType />}
                isLoggedIn={isLoggedIn}
              />
            }
          />
          <Route
            path="services"
            element={
              <ProtectedRoute element={<Service />} isLoggedIn={isLoggedIn} />
            }
          />
          <Route
            path="createservice"
            element={
              <ProtectedRoute
                element={<CreateService />}
                isLoggedIn={isLoggedIn}
              />
            }
          />
          <Route
            path="createorder"
            element={
              <ProtectedRoute
                element={<Createorder />}
                isLoggedIn={isLoggedIn}
              />
            }
          />
        </Routes>

        <footer className="main-footer bg-info text-white">
          <strong>Copyright © 2024 Shanzio Info Tech</strong>
        </footer>
      </div>
    </div>
  );
}

export default App;
