import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import Swal from "sweetalert2";

const ServiceType = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    servicetype: "",
  });
  const [errors, setErrors] = useState({});
  const [customers, setCustomers] = useState([]);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get("/servicetype");
      if (response?.status === "success") {
        setCustomers(response.data); // Assuming data is an array of customers
      } else {
        console.error("Error fetching customers:", response);
        setCustomers([]);
      }
    } catch (error) {
      setCustomers([]);
      console.error("Error fetching customers:", error);
    }
  };

  const handleModalOpen = (customer) => {
    setShowModal(true);
    if (customer == undefined || customer == null) {
      setFormData({ servicetype: "" });
      setErrors({});
    } else {
      setFormData(customer);
      setErrors({});
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    // Required fields
    if (!formData.servicetype)
      newErrors.servicetype = "service type is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (formData.id) {
        const data = new FormData();
        data.append("servicetype", formData.servicetype);
        data.append("active", formData.active);

        try {
          // Call the API using POST method
          const response = await axiosInstance.put(
            "/servicetype?id=" + formData.id,
            data
          );
          // Check the response from the API
          if (response?.status === "success") {
            console.log("Response data:", response);
            // Fetch updated customers after adding a new customer
            fetchCustomers();
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Service Type" + response.message,
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                popup: "custom-toast",
              },
            });
            handleModalClose();
          } else {
            console.error("Error in response:", response);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        const data = new FormData();
        data.append("servicetype", formData.servicetype);

        try {
          // Call the API using POST method
          const response = await axiosInstance.post("/servicetype", data);

          // Check the response from the API
          if (response?.status === "success") {
            console.log("Response data:", response);
            // Fetch updated customers after adding a new customer
            fetchCustomers();
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Service Type" + response.message,
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                popup: "custom-toast",
              },
            });
            handleModalClose();
          } else {
            console.error("Error in response:", response.data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`/servicetype?id=${id}`);
      if (response?.status === "success") {
        console.log("servicetype deleted successfully:", response.data);
        // Fetch updated customers after deletion
        fetchCustomers();
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Service Type" + response.message,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          customClass: {
            popup: "custom-toast",
          },
        });
      } else {
        console.error("Error deleting servicetype:", response);
      }
    } catch (error) {
      console.error("Error deleting servicetype:", error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []); // Empty dependency array to run once on mount

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">
                ServiceTypes
              </h1>
            </div>
            <div className="col-sm-6 text-right align-self-center">
              <button
                type="button"
                data-toggle="modal"
                data-target="#modal-default"
                className="align-top btn btn-sm btn-info d-inline-block"
                onClick={() => {
                  handleModalOpen(null);
                }}
              >
                + Add New
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card mx-3 shadow">
        <div className="card-body table-responsive">
          <table id="type-list" className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>S NO.</th>
                <th>Service Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => (
                <tr key={customer.id}>
                  <td>{index + 1}</td>
                  <td>{customer.servicetype}</td>
                  <td>{customer.active == 1 ? "Active" : "InActive"}</td>
                  <td>
                    {/* Add actions like edit or delete here if needed */}
                    <button
                      className="btn btn-dark btn-sm"
                      onClick={() => handleModalOpen(customer)}
                    >
                      Edit
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDelete(customer.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>S NO.</th>
                <th>Service Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modal-default"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {formData.id ? "Service Type Edit" : "Service Type Add"}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleModalClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form id="add_Types" method="POST" onSubmit={handleSubmit}>
              <div className="modal-body">
                <div className="form-group">
                  <label>
                    Service Type Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="servicetype"
                    className="form-control"
                    value={formData.servicetype}
                    onChange={handleChange}
                    placeholder="Enter Service Type Name"
                  />

                  {errors.servicetype && (
                    <small className="text-danger">{errors.servicetype}</small>
                  )}
                </div>
                {formData.id ? (
                  <div className="form-group">
                    <label>
                      Status <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="active"
                      style={{ width: "100%;" }}
                      value={formData.active}
                      onChange={handleChange}
                    >
                      <option disabled>Select The Status</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="modal-footer justify-content-between">
                <button type="submit" className="btn btn-info ">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceType;
