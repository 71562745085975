import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Receipt from "./peint";

const Createorder = () => {
  const [serviceAll, setServiceAll] = useState([]);
  const [Selectedservice, setSelectedService] = useState({});
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceModel, setServiceModel] = useState(false);
  const [paymentModel, setPaymentModel] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [order, setOrder] = useState({});
  const [customer, setCustomer] = useState({});
  const today = new Date().toISOString().split("T")[0];
  const [selectedServicesType, setSelectedServicesType] = useState([]);

  // Handle checkbox change
  const handleCheckboxChange = (event, service) => {
    const { checked, value } = event.target;
    if (checked) {
      setSelectedServicesType((prev) => [...prev, service]); // Add the service if checked
    } else {
      setSelectedServicesType((prev) => prev.filter((s) => s !== service)); // Remove the service if unchecked
    }
  };

  const calculateTotal = () => {
    const subTotalElements = document.querySelectorAll(".sub-total");
    let total = 0;

    // Iterate over the NodeList of elements and parse each value as an integer/float
    subTotalElements.forEach((element) => {
      const value = parseFloat(element.textContent);

      // Add the parsed value to the total sum
      if (!isNaN(value)) {
        total += value;
      }
    });
    setOrder((prevState) => ({
      ...prevState,
      totalAmt: total,
    }));
    // Display the result in the 'total-result' paragraph
    document.getElementById("total-amount").innerHTML = ` Rs: ${total}`;
  };

  const onchangeserivceQty = (event, index) => {
    const { value } = event.target;
    const updatedQty = parseInt(value) || 0;

    // Update the quantity for the specific service in the order state
    setOrder((prevOrder) => {
      const updatedServices = prevOrder.services.map((service, i) => {
        if (i === index) {
          return { ...service, qty: updatedQty }; // Update qty for the selected service
        }
        return service; // Keep the other services unchanged
      });

      return {
        ...prevOrder,
        services: updatedServices, // Set the updated services in the order state
      };
    });
    setTimeout(() => {
      calculateTotal();
    }, 50);
  };

  const handleCustomerSearch = async (e) => {
    const input = e.target.value;
    setCustomerName(input);
    setCustomer({});
    setOrder((prevState) => {
      const { customerid, customername, ...newState } = prevState;
      return newState;
    });
    // Filter customers based on input
    if (input.length > 0) {
      try {
        const response = await axiosInstance.get(
          "/customer?customerName=" + input + "%"
        );
        if (response?.status === "success") {
          setFilteredCustomers(response.data);
        } else {
          console.error("Error fetching customers:", response);
          setFilteredCustomers([]);
        }
      } catch (error) {
        setFilteredCustomers([]);
        console.error("Error fetching customers:", error);
      }
    } else {
      setFilteredCustomers([]);
    }
  };

  const handleCustomerSelect = (customer) => {
    setCustomerName(customer.customerName);
    setCustomer(customer);
    setOrder((prevState) => ({
      ...prevState,
      customerid: customer.id,
      customername: customer.customerName,
    }));
    setFilteredCustomers([]);
  };

  const fetchService = async () => {
    try {
      const response = await axiosInstance.get("/service");
      if (response?.status === "success") {
        setServiceAll(response.data);
      } else {
        console.error("Error fetching customers:", response);
        setServiceAll([]);
      }
    } catch (error) {
      setServiceAll([]);
      console.error("Error fetching customers:", error);
    }
  };

  const OpenSeriveModel = async (service) => {
    setSelectedService(service);
    const response2 = await axiosInstance.get(
      "/serviceprice?columns=id,service,serviceid,servicename,price&serviceid=" +
        service.id
    );

    if (response2?.status === "success") {
      console.log(response2.data);

      setServiceTypes(response2.data);
    }
    setServiceModel(true);
  };

  const getOrderCount = async () => {
    try {
      const response = await axiosInstance.get(
        "/orders?columns=count(*) as count"
      );

      if (response?.status === "success") {
        const count = response?.data[0]?.count + 1;
        setOrder({ orderid: "ORD-00" + count, orderdate: today });
        console.log(response.data);
      } else {
        setOrder({ orderid: "ORD-001", orderdate: today });
        console.error("Error fetching customers:", response);
      }
    } catch (error) {
      setOrder({ orderid: "ORD-001", orderdate: today });
      console.error("Error fetching customers:", error);
    }
  };

  const fetchOrdersDetails = () => {
    fetchService();
    getOrderCount();
  };

  const addservice = () => {
    if (selectedServicesType.length > 0) {
      setOrder((prevOrder) => ({
        ...prevOrder,
        services: [
          ...(prevOrder.services || []), // Keep previous services if any
          ...selectedServicesType.map((servicetype) => ({
            serviceName: servicetype.servicename,
            serviceId: servicetype.id,
            servicetypeName: servicetype.service,
            servicetypeId: servicetype.id,
            price: servicetype.price,
            qty: 1,
          })),
        ],
      }));
      setSelectedServicesType([]);
    }
    const checkboxes = document.querySelectorAll(".my-checkbox");

    // Iterate over each checkbox and set its checked property to false
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    setTimeout(() => {
      calculateTotal();
    }, 100);
    setServiceModel(false); // Close the modal after adding the service
  };

  const saveOrder = () => {
    if (validateOrder(order)) {
      setPaymentModel(true);
      setOrder((prevOrder) => ({
        ...prevOrder,
        discount: 0,
        deliveryDate: today,
        deliveryDate: today,
        netAmt: order.totalAmt,
        paidAmt: 0,
        paymentType: "",
        remark: "",
      }));
    }
  };

  const generateAndPrintOrderPDF = () => {
    try {
      const doc = new jsPDF();

      // Add title
      doc.setFontSize(18);
      doc.text("Order Receipt", 14, 22);

      // Add order details in table format
      doc.autoTable({
        startY: 30,
        head: [["Order ID", "Customer Name", "Delivery Date", "Total Amount"]],
        body: [
          [
            order.orderid || "N/A",
            order.customername || "N/A",
            order.deliveryDate || "N/A",
            `Rs: ${order.totalAmt || 0}`,
          ],
        ],
      });

      // Add more detailed order information
      const detailsStartY = doc.autoTable.previous.finalY + 10;
      doc.text(`SubTotal: Rs ${order.totalAmt || 0}`, 14, detailsStartY);
      doc.text(`Discount: Rs ${order.discount || 0}`, 14, detailsStartY + 10);
      doc.text(`Net Amount: Rs ${order.netAmt || 0}`, 14, detailsStartY + 20);
      doc.text(`Paid Amount: Rs ${order.paidAmt || 0}`, 14, detailsStartY + 30);
      doc.text(
        `Payment Type: ${order.paymentType || "N/A"}`,
        14,
        detailsStartY + 40
      );
      doc.text(`Remarks: ${order.remark || "N/A"}`, 14, detailsStartY + 50);

      // Save the PDF to a blob
      const pdfBlob = doc.output("blob");

      // Create a URL for the blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create an iframe to hold the PDF
      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // Hide the iframe
      document.body.appendChild(iframe);

      // Load the PDF into the iframe
      iframe.src = pdfUrl;

      // Print the document when it's loaded
      iframe.onload = function () {
        setTimeout(() => {
          try {
            iframe.contentWindow.focus(); // Ensure the iframe is focused
            iframe.contentWindow.print(); // Trigger the print dialog
            // Clean up
            URL.revokeObjectURL(pdfUrl);
            document.body.removeChild(iframe);
          } catch (printError) {
            console.error("Printing error:", printError);
          }
        }, 100);
      };
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const createOrder = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    Object.keys(order).forEach((key) => {
      if (key !== "services") {
        formData.append(key, order[key]);
      }
    });

    formData.append("status", "Open");

    try {
      // Call the API using POST method
      const response = await axiosInstance.post("/orders", formData);

      // Check the response from the API
      if (response?.status === "success") {
        console.log("Response data:", response);

        var failed = false;

        order.services?.map(async (item, index) => {
          var servicePrice = item;
          servicePrice["orderid"] = response.data?.id;
          const response1 = await axiosInstance.post(
            "/orderitems",
            servicePrice
          );

          if (response1?.status !== "success") {
            failed = true;
          }

          if (index + 1 == order.services?.length) {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: failed ? "error" : "success",
              title: "Order " + response.message,
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                popup: "custom-toast",
              },
            });
            printReceipt(order);
            // setPaymentModel(false);
          }
        });
      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Order " + response.message,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          customClass: {
            popup: "custom-toast",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function validateOrder(order) {
    if (!order.customerid || !order.customername) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        title: "Customer details empty, Select Customer",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        customClass: {
          popup: "custom-toast",
        },
      });
      return false;
    }

    if (!Array.isArray(order.services) || order.services.length === 0) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        title: "Add at least one service",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        customClass: {
          popup: "custom-toast",
        },
      });
      return false;
    }
    return true;
  }

  useEffect(() => {
    fetchOrdersDetails();
  }, []);

  useEffect(() => {
    console.log(order, selectedServicesType, Selectedservice);
  }, [order]);

  const printReceipt = (receiptData) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Receipt</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 10px;
            }
            .receipt {
              border: 1px solid #000;
              padding: 10px;
              width: 500px;
              margin: auto;
            }
            h1 {
              text-align: center;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin: 10px 0;
            }
            th, td {
              border: 1px solid #000;
              padding: 5px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            .services {
              margin-top: 20px;
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          <div class="receipt">
            <h1>Receipt</h1>
            <table>
              <tr>
                <th>Order ID</th>
                <td>${receiptData.orderid}</td>
              </tr>
              <tr>
                <th>Customer Name</th>
                <td>${receiptData.customername}</td>
              </tr>
                <tr>
                <th>Customer Phone</th>
                <td>${customer.phone}</td>
              </tr>
              <tr>
                <th>Order Date</th>
                <td>${receiptData.orderdate}</td>
              </tr>
              <tr>
                <th>Delivery Date</th>
                <td>${receiptData.deliveryDate}</td>
              </tr>
            </table>
            <div class="services">
              <h2>Items</h2>
              <table>
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th>Service Type</th>
                    <th>Quantity</th>
                    <th>Price per Quantity </th>
                     <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  ${receiptData.services
                    .map(
                      (service) => `
                    <tr>
                      <td>${service.serviceName}</td>
                      <td>${service.servicetypeName}</td>
                      <td>${service.qty}</td>
                      <td>$${service.price}</td>
                      <td>$${service.qty * service.price}</td>
                    </tr>
                  `
                    )
                    .join("")}
                </tbody>
              </table>
            </div>
            <table>
              <tr>
                <th>Total Amount</th>
                <td>$${receiptData.totalAmt}</td>
              </tr>
              <tr>
                <th>Discount</th>
                <td>$${receiptData.discount}</td>
              </tr>
             
              <tr>
                <th>Payment Type</th>
                <td>${
                  receiptData.paymentType == "" ? "-" : receiptData.paymentType
                }</td>
              </tr>
              <tr>
                <th>Net Amount</th>
                <td>$${receiptData.netAmt}</td>
              </tr>
              <tr>
                <th>Paid Amount</th>
                <td>$${receiptData.paidAmt}</td>
              </tr>
               <tr>
                <th>Balance Amount</th>
                <td>$${
                  receiptData.totalAmt -
                  (receiptData.paidAmt + receiptData.discount)
                }</td>
              </tr>
               
            </table>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1 className="m-0 mr-2 text-dark d-inline-block">Add Order</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="../dashboard.html">Dashboard</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="../orders.html">Orders</a>
                </li>
                <li className="breadcrumb-item active">Add Order</li>
              </ol>
            </div>
            <div className="col-sm-6 text-right align-self-center"></div>
          </div>
        </div>
      </div>
      <section className="content card mx-3 shadow">
        <div className="container-fluid card-body">
          <div className="row">
            <div className="col-md-5">
              <div className="card card-info">
                <div className="card-body">
                  <div className="row">
                    {serviceAll.map((item, index) => (
                      <div
                        key={"service" + index}
                        className="col-3 service-box text-center mb-3"
                        onClick={() => {
                          OpenSeriveModel(item);
                        }}
                      >
                        <a>
                          <img
                            className="profile-user-img img-fluid"
                            src={`../../images/${item.serviceImg}`}
                            alt="Shirt"
                          />

                          <p className="text-muted text-center my-0">
                            {item.serviceName}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <div className="card card-info">
                <div className="card-body">
                  <div className="row">
                    <div className="col-10 form-group mb-4">
                      <div className="autocomplete-container">
                        <input
                          type="text"
                          name="customer"
                          id="customer_name"
                          className="form-control"
                          value={customerName}
                          onChange={handleCustomerSearch}
                          placeholder="Enter Customer Name"
                          autoComplete="off"
                        />
                        <div id="customerList">
                          {filteredCustomers.length > 0 && (
                            <ul className="order-customer dropdown-menu">
                              {filteredCustomers.map((customer) => (
                                <li
                                  key={customer.id}
                                  className="dropdown-item"
                                  onClick={() => handleCustomerSelect(customer)}
                                >
                                  {customer.customerName} - {customer.phone}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-2 text-center">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#modal-default"
                        className="align-top btn btn-sm btn-info d-inline-block"
                      >
                        <i className="fas fa-plus"></i>Add
                      </button>
                    </div>

                    <div className="col-6">
                      <div className="form-group mb4">
                        <input
                          type="text"
                          className="form-control"
                          value={order.orderid}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group mb4">
                        <input
                          type="date"
                          className="form-control orderDate"
                          name="orderDate"
                          value={order.orderdate}
                          placeholder="Enter Date"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="card-body p-0">
                        <table
                          className="table mb-3"
                          style={{ minHeight: "350px" }}
                        >
                          <thead className="btn-secondary disabled">
                            <tr>
                              <th>Service</th>
                              <th>ServiceType</th>
                              <th>Qty</th>
                              <th>Rate</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.services?.map((service, i) => (
                              <tr>
                                <td>{service.serviceName}</td>
                                <td>{service.servicetypeName}</td>
                                <td>
                                  <input
                                    style={{ width: "100%" }}
                                    type="number"
                                    value={service.qty || 1} // Default qty to 1 if undefined
                                    onChange={(e) => onchangeserivceQty(e, i)} // Pass index to identify the service
                                    min="1"
                                  />
                                </td>
                                <td>{service.price}</td>
                                <td className="sub-total">
                                  {service.price * service.qty}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="3">
                                <button
                                  type="button"
                                  className="align-top btn btn-sm btn-info d-inline-block save"
                                  onClick={saveOrder}
                                >
                                  Save And Continue
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    window.location.reload();
                                  }}
                                >
                                  Clear All
                                </button>
                              </td>
                              <td>
                                <b>Total Amount:</b>
                              </td>
                              <td>
                                <span id="total-amount"></span>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="modal-default">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Add Customer</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <form id="CreateCustomer" method="POST">
                    <div className="form-group">
                      <label>
                        Customer Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control new-customer"
                        name="customer"
                        value=""
                        placeholder="Enter Customer Name"
                        autoComplete="off"
                      />
                    </div>

                    <div className="form-group">
                      <label>
                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-phone"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          data-inputmask='"mask": "(999) 999-9999"'
                          data-mask
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value=""
                        placeholder="Enter User Email"
                      />
                    </div>
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        value=""
                        placeholder="Enter User Address"
                      />
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="submit" className="btn btn-info ">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`modal fade ${paymentModel ? "show" : ""}`}
            style={{ display: paymentModel ? "block" : "none" }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addCustomerModalLabel"
            aria-hidden={!paymentModel}
            aria-modal="true"
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Payment Details</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setPaymentModel(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <form className="form-horizontal">
                  {" "}
                  <div className="modal-body">
                    <div className="row">
                      {/* <div className="col-12">
                        <div className="form-group clearfix addon-box">
                          <ul className="list-group flex-row row">
                            <li className="list-group-item col-6 border">
                              <input
                                type="checkbox"
                                className="addon_item"
                                name="addon_price[]"
                                id="6"
                                data-price="10"
                                value="6"
                              />
                              <label for="6">Urgent</label>
                            </li>

                          </ul>
                        </div>
                      </div> */}
                      <div className="col-6">
                        <div className="form-group">
                          <label>Delivery Date</label>
                          <input
                            type="date"
                            name="delivery_date"
                            className="form-control delivery_date"
                            value={order.deliveryDate}
                            onChange={(e) => {
                              setOrder((prevState) => ({
                                ...prevState,
                                deliveryDate: e.target.value,
                              }));
                            }}
                            min={today}
                            placeholder="Enter Date"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>Discount</label>
                          <input
                            type="number"
                            className="form-control discount"
                            name="discount"
                            min="0"
                            value={order.discount}
                            onChange={(e) => {
                              setOrder((prevState) => ({
                                ...prevState,
                                discount: parseFloat(e.target.value),
                                netAmt:
                                  order.totalAmt - parseFloat(e.target.value),
                              }));
                            }}
                            placeholder="Enter Discount"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <ul className="list-group list-group-unbordered mb-3">
                          <li className="list-group-item">
                            <b>SubTotal:</b>{" "}
                            <span className="float-right">
                              Rs:{" "}
                              <span className="total-amount">
                                {order.totalAmt}
                              </span>
                            </span>
                          </li>
                          {/* <li className="list-group-item">
                            <b>Addon:</b>{" "}
                            <span className="float-right">
                              Rs: <span className="addon">0</span>
                            </span>
                          </li> */}
                          <li className="list-group-item">
                            <b>Discount:</b>{" "}
                            <span className="float-right">
                              Rs:{" "}
                              <span className="discount">{order.discount}</span>
                            </span>
                          </li>
                          <li className="list-group-item">
                            <b>Gross Total:</b>{" "}
                            <span className="float-right">
                              Rs:{" "}
                              <span className="grand-total">
                                {order.netAmt}
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>Paid Amount</label>
                          <input
                            type="number"
                            className="form-control paid_amount"
                            name="paid_amount"
                            value={order.paidAmt}
                            onChange={(e) => {
                              setOrder((prevState) => ({
                                ...prevState,
                                paidAmt: parseFloat(e.target.value),
                              }));
                            }}
                            placeholder="Enter Paid Amount"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>Payment Type</label>
                          <select
                            className="form-control payment_method"
                            name="payment_method"
                            value={order.paymentType}
                            onChange={(e) => {
                              setOrder((prevState) => ({
                                ...prevState,
                                paymentType: e.target.value,
                              }));
                            }}
                          >
                            <option disabled="" selected="" value="">
                              Select The Payment Type
                            </option>
                            <option value="cash">Cash</option>
                            <option value="card">Card</option>
                            <option value="cheque">cheque</option>
                            <option value="bank_transfer">Bank Transfer</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Remarks</label>
                          <textarea
                            className="form-control remark"
                            rows="3"
                            value={order.remark}
                            name="remark"
                            onChange={(e) => {
                              setOrder((prevState) => ({
                                ...prevState,
                                remark: e.target.value,
                              }));
                            }}
                            placeholder="Enter Remark Details"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-info text-right"
                        onClick={createOrder}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className={`modal fade ${serviceModel ? "show" : ""}`}
            style={{ display: serviceModel ? "block" : "none" }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addCustomerModalLabel"
            aria-hidden={!serviceModel}
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Select Service Type</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setServiceModel(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {serviceTypes.map((service, index) => (
                    <div
                      className="icheck-success"
                      key={`${Selectedservice.serviceName}${index}`}
                    >
                      <input
                        type="checkbox"
                        className="my-checkbox"
                        id={Selectedservice.serviceName + service.id}
                        onChange={(e) => handleCheckboxChange(e, service)}
                      />
                      <label htmlFor={Selectedservice.serviceName + service.id}>
                        {service.service}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="modal-footer justify-content-end">
                  <button
                    type="button"
                    className="btn btn-info text-right add_order"
                    onClick={addservice}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Createorder;
